import React, { CSSProperties } from "react";
import cx from "classnames";
import styles from "./header-navigation.module.scss";
import { useRouter } from "next/router";
import Link from "next/link";

export interface IHeaderNavigation {
  menu: { label: React.ReactNode; href: string; exact?: boolean }[];
  className?: string;
  style?: CSSProperties;
  htmlId?: string;
  sticky?: boolean;
}

export const HeaderNavigation = (props: IHeaderNavigation) => {
  const router = useRouter();

  return (
    <div
      className={cx(styles.header_navigation, props.sticky && styles.sticky, props.className)}
      style={props.style}
      id={props.htmlId}
    >
      <div className={styles.menu}>
        <div className={styles.inner}>
          {props.menu.map((it, key) => {
            const active = it.exact ? router.asPath === it.href : router.asPath.startsWith(it.href);

            return (
              <Link href={it.href} className={cx(styles.item, active && styles.active)} key={it.href}>
                {it.label}
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

HeaderNavigation.defaultProps = {};
