import { useGuardAuthedOnly } from "modules/auth";
import { CompanyExpired } from "modules/company";
import { HeaderDashboard, HeaderNavigation, HeaderTitle } from "modules/layout";
import { useRouter } from "next/router";
import pages from "pages/_router";
import React, { CSSProperties } from "react";
import { api } from "shared/api";

export interface ICompanyLayout {
  title?: React.ReactNode;
  back?: { title: React.ReactNode; href: string };
  actions?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  htmlId?: string;
  stickyNavigation: boolean;
}

export const CompanyLayout = (props: ICompanyLayout) => {
  const { query } = useRouter();
  const companySlug = query.company as string;

  const company = api.company.useCompanyGet(companySlug);

  useGuardAuthedOnly();

  return (
    <div className={props.className} style={props.style} id={props.htmlId}>
      <HeaderDashboard />

      <HeaderNavigation
        menu={[
          { label: "Финансы", href: pages.company(companySlug).finances.href },
          // { label: "Департаменты", href: pages.company(companySlug).departments.href },
          { label: "Настройки", href: pages.company(companySlug).settings.href },
        ]}
        sticky={props.stickyNavigation}
      />

      {company.data && !company.data.hasAccess ? (
        <CompanyExpired />
      ) : (
        <>
          {props.title && (
            <HeaderTitle fullWidth back={props.back} actions={props.actions}>
              {props.title}
            </HeaderTitle>
          )}

          {props.children}
        </>
      )}
    </div>
  );
};

CompanyLayout.defaultProps = {
  stickyNavigation: true,
};
