import cx from "classnames";
import React, { CSSProperties } from "react";
import styles from "./company-expired.module.scss";

export interface ICompanyExpired {
  className?: string;
  style?: CSSProperties;
  htmlId?: string;
}

export const CompanyExpired = (props: ICompanyExpired) => {
  return (
    <div className={cx(styles.company_expired, props.className)} style={props.style} id={props.htmlId}>
      <div className={styles.inner}>
        <h1>Для этой компании закончился период доступа</h1>
      </div>
    </div>
  );
};

CompanyExpired.defaultProps = {};
