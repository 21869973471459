import React, { useEffect } from "react";
import { CompanyLayout } from "./_layout";
import Router, { useRouter } from "next/router";
import pages from "pages/_router";

const IndexPage = () => {
  const { query } = useRouter();
  const slug = query.company as string;

  useEffect(() => {
    Router.replace(pages.company(slug).finances.href);
  });

  return <CompanyLayout />;
};

export default IndexPage;
